<template>
 <div class="flex flex-col">
  <div class="overflow-x-auto">
   <div class="inline-block min-w-full align-middle py-2 px-2">
    <div
     class="relative overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
     <table
      class="min-w-full divide-y divide-gray-300 dark:divide-slate-400 ">
      <thead class="bg-gray-50 dark:bg-slate-700">
       <tr
        v-show="this.tableDataExtensions.length > 0">
        
        <th
         scope="col"
         v-for="header in this.tableHeaders"
         :key="header"
         :title="$t(header.name)"
         :class="[
          header.name !== 'declined' &&
          header.name !== 'abandoned' &&
          header.name !== 'answered'
           ? 'text-gray-900 dark:text-slate-200'
           : header.name === 'declined' || header.name === 'abandoned'
           ? 'text-red-600'
           : header.name === 'answered'
           ? 'text-green-600'
           : 'text-gray-900 dark:text-slate-200',
          'px-1 py-3 text-center text-sm font-semibold',
         ]"
         >
         <div
          @click="setSorting(header.key)"
          class="flex w-auto text-center justify-center">
          <span>
           {{ $t(header.name) }}
          </span>
         </div>
        </th>
       </tr>
      </thead>
      <tbody
       v-show="this.tableDataExtensions.length > 0"
       class="divide-y divide-gray-200 bg-white dark:bg-slate-500 dark:divide-slate-400">
       <tr
        v-for="data in this.tableData"
        :key="data.id"
        :class="[
         'hover:bg-slate-100 dark:hover:bg-slate-400 cursor-pointer',
        ]"
        @click="$emit('selectAgentNumber', data.extension, data.agent)">
        <td v-for="header in tableHeaders" :key="header.key" class="whitespace-nowrap px-1 py-3 text-sm max__body__width truncate text-center">
         <span v-if="header.key === 'extension'">
          {{ data.extension }} - {{ data.agent ? data.agent : "" }}
         </span>
         <span v-else>
          {{
           header.key.includes("duration") ||
           header.key.includes("sec") ||
           header.key.includes("avg") ||
           header.key.includes("talking")
            ? formatSecsToHourMinAndSecs(parseInt(data[header.key]))
            : (header.key.includes("answered") ||
               header.key.includes("unanswered")) &&
              data["calls"]
            ? data[header.key] +
              `(${getPercentageValue(data["calls"], data[header.key])}%)`
            : data[header.key]
          }}
         </span>
        </td>
       </tr>
      </tbody>
     </table>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Switch } from "@headlessui/vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
 props: ["account", "tableData", "tableDataExtensions", "enabled"],
 components: {
  Loading,
  Switch,
 },
 data() {
  return {
   search: "",
   isLoading: true,
   fullPage: false,
   activeSorting: "",
   tableHeaders: [
    {
     name: "extension",
     key: "extension",
     sort: "extension",
     isSort: false,
     description: this.$t("extension"),
    },
    {
     name: "calls",
     key: "calls",
     sort: "calls",
     isSort: false,
     description: this.$t("calls"),
    },
    {
     name: "answered",
     key: "answered",
     sort: "answered",
     isSort: false,
     description: this.$t("answered"),
    },
    // {
    //  name: "unanswered",
    //  key: "unanswered",
    //  sort: "unanswered",
    //  isSort: false,
    //  description: this.$t("unanswered"),
    // },
    {
     name: "avgTalkingDuration",
     key: "avg_talking_duration",
     sort: "avg_talking_duration",
     isSort: false,
     description: this.$t("avgTalkingDuration"),
    },
    {
     name: "talking",
     key: "talking",
     sort: "talking",
     isSort: false,
     description: this.$t("talking"),
    },
    // {
    //  name: "avgRingingDuration",
    //  key: "avg_ringing_duration",
    //  sort: "avg_ringing_duration",
    //  isSort: false,
    //  description: this.$t("avgRingingDuration"),
    // },
   ],
  };
 },
 methods: {
  formatSeconds(value) {
   var minutes = Math.floor(value / 60);
   var seconds = value - minutes * 60;
   return minutes + "(min)" + ":" + seconds + "(s)";
  },
  splitGlobalNames(str) {
   let splitName = str.split(",");
   splitName.shift();
   return splitName;
  },
  getPercentageValue(total, value) {
   return ((parseInt(value) / parseInt(total)) * 100).toFixed(0);
  },
  getAverageTime(time, calls) {
   let avg = undefined;
   if (calls > 0) {
    avg = time / calls;
    if (avg > 0) {
     avg = this.timeFormater(avg);
    }
   } else {
    avg = 0;
   }
   return avg;
  },
  setSorting(sort, header) {
   this.$emit("sort", sort);
   this.activeSorting = header;
  },
  getYear(date) {
   const dateCreated = new Date(date);
   const formatedDate = dateCreated.getFullYear();

   return String(formatedDate);
  },
  getMonth(date) {
   const dateCreated = new Date(date);
   const formatedDate = ("0" + (dateCreated.getMonth() + 1)).slice(-2);
   return String(formatedDate);
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
  formatDateFrench(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
  setSorting(sort) {
   this.$emit("sort", sort);
  },
 },
 mounted() {
  console.log("tablePaginatedData", this.tablePaginatedData);
 },
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style>
html,
body {
 height: 100%;
}

@media (min-width: 640px) {
 table {
  display: inline-table !important;
 }

 thead tr:not(:first-child) {
  height: 40px;
  display: none;
 }
}
@media only screen and (max-width: 640px) {
 td {
  height: 40px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
 }

 th {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  height: 40px;
 }
}
</style>
